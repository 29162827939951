









































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
@Component
/*
@group HSZG
A contact box currently only used in the job ad editor.
It it designed to display a hszg contact similar to the ones on the hzsg.de website.
*/
export default class ContactBox extends Vue {
  // h3 contact box title
  @Prop() contactBoxTitle?: string
  // the contact itself - check the component for its contents (name, address etc.)
  @Prop() contact?: {
    photo?: { mimeType: string; content: string };
    title?: string;
    firstName: string;
    lastName: string;
    email?: string;
    locations: [
      {
        title: string;
        address: {
          title: string;
          lines: [string];
        };
        phone?: string;
      }
    ];
  }
}
