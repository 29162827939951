





































































import Vue from 'vue'
import Component from 'vue-class-component'
import { TableFields } from '@/store/types'
import { CompetenceEvaluation, JobAdType } from '@/store/jobAd/types'
import { Prop } from 'vue-property-decorator'
import { Form, KeyValue } from '@/store/commonFormInput/types'
import { State } from 'vuex-class'
import BaseForm from '@/components/forms/BaseForm.vue'
import formTypes from '@/common/formTypes'
import Competence from '@/components/hszg/Competence.vue'
@Component({
  components: { BaseForm, Competence }
})
// Component for the modal in the fourth step of the job ad editor (questionnaire for evaluating the competences)
// @group JOB AD EDITOR
export default class CompetenceEvaluationTable extends Vue {
  @State('currentScreenWidth') screenWidth: number

  @Prop()
  competenceEvaluations: CompetenceEvaluation[]

  @Prop({ required: false })
  jobAd: JobAdType

  get fields (): TableFields[] {
    return [
      { key: 'competence', label: 'Kompetenz' },
      { key: 'value', label: 'Bewertung' }
    ]
  }

  get activityFields (): Form[] {
    return [
      {
        id: 'activity-checkboxes',
        key: 'activities',
        type: formTypes.CHECKBOXGROUP,
        label: this.$i18n.t('job-ad.competences.self-check').toString(),
        options: this.jobAd.activities?.map(activity => {
          return {
            text: activity,
            value: activity
          }
        }),
        required: false,
        md: 12
      }
    ]
  }

  get radioOptions (): KeyValue[] {
    return [
      { text: this.$i18n.t('job-ad.competences.least-important').toString(), value: '1' },
      { text: this.$i18n.t('job-ad.competences.less-important').toString(), value: '2' },
      { text: this.$i18n.t('job-ad.competences.rather-important').toString(), value: '3' },
      { text: this.$i18n.t('job-ad.competences.more-important').toString(), value: '4' },
      { text: this.$i18n.t('job-ad.competences.very-important').toString(), value: '5' },
      { text: this.$i18n.t('job-ad.competences.most-important').toString(), value: '6' }
    ]
  }

  items: CompetenceEvaluation[] = []
  checkedActivities: string[] = []

  mounted (): void {
    if (this.competenceEvaluations.length === 0) {
      for (let i = 0; i < 64; i++) {
        const object = {
          value: '0',
          competence: Object.keys(this.$i18n.t('all-competences'))[i].toString()
        }
        this.items.push(object)
      }
    }
  }
}
