

























































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { CompetenceCorridorForFrontend, CompetenceCorridorServerObject } from '@/store/kode/types'
import { CompetenceEvaluation, JobAdType } from '@/store/jobAd/types'
import CompetencesEditor from '@/components/forms/CompetencesEditor.vue'
import CompetenceEvaluationTable from '@/components/forms/job-ad-editor/CompetenceEvaluationTable.vue'
import { Action } from 'vuex-class'
import {
  TRANSFORM_COMPETENCE_CORRIDORS_FOR_BACKEND,
  TRANSFORM_COMPETENCE_CORRIDORS_FOR_FRONTEND
} from '@/store/kode/actions'
import ContactBox from '@/components/hszg/ContactBox.vue'
@Component({
  components: { ContactBox, CompetenceEvaluationTable, CompetencesEditor }
})
// Component for the fourth step of the job ad editor (company sets competence requirement)
// @group JOB AD EDITOR
export default class Competences extends Vue {
  $refs!: {
    competenceEditorRef: CompetencesEditor;
  }

  @Action(TRANSFORM_COMPETENCE_CORRIDORS_FOR_BACKEND)
  transformCorridorsForBackend: (toTransform: Array<CompetenceCorridorForFrontend>) => Promise<Array<CompetenceCorridorServerObject>>

  @Action(TRANSFORM_COMPETENCE_CORRIDORS_FOR_FRONTEND)
  transformCorridorsForFrontend: (toTransform: Array<CompetenceCorridorServerObject>) => Promise<Array<CompetenceCorridorForFrontend>>

  // is the form readonly (input user right)
  @Prop({ default: false })
  readonly: boolean

  // is the form writeable (input user right)
  @Prop({ default: false })
  writeable: boolean

  @Prop()
  internalModel: JobAdType

  @Watch('internalModel', { immediate: true })
  onJobAdChanged (newValue: JobAdType): void {
    if (newValue.competences) {
      this.transformCorridorsForFrontend(newValue.competences).then(data => {
        this.selectedCorridors = data
      })
    }
  }

  private selectedCorridors: Array<CompetenceCorridorForFrontend> = new Array<CompetenceCorridorForFrontend>()
  private competenceEvaluations: CompetenceEvaluation[] = []
  private aCompetenceIncluded = false
  private pCompetenceIncluded = false
  private fCompetenceIncluded = false
  private sCompetenceIncluded = false

  public gotoStep2 (): void {
    this.selectedCorridors = []
    this.aCompetenceIncluded = false
    this.pCompetenceIncluded = false
    this.fCompetenceIncluded = false
    this.sCompetenceIncluded = false
    this.computeSuggestion()
    this.emitChanges(this.selectedCorridors)
    this.$root.$emit('bv::toggle::collapse', 'accordion-2')
  }

  public computeSuggestion (): void {
    this.selectedCorridors = []

    this.competenceEvaluations.sort((a, b) => {
      return Number(b.value) - Number(a.value)
    })

    const topTwelve = this.competenceEvaluations.slice(0, 12)
      .filter(compentence => {
        return compentence.value !== '0'
      })

    this.addToSelectedCompetenceCorridors(topTwelve)

    if (topTwelve.length === 12 && (!this.aCompetenceIncluded || !this.pCompetenceIncluded || !this.fCompetenceIncluded || !this.sCompetenceIncluded)) {
      let possibleChoices = this.competenceEvaluations.slice(12)
        .filter(compentence => {
          return compentence.value !== '0'
        })

      possibleChoices = possibleChoices.filter(competence => {
        const baseCompetences = this.$i18n.t('all-competences.' + competence.competence + '.shortCompetences').toString().split(',') as string[]
        if (!this.aCompetenceIncluded && baseCompetences.includes('A')) {
          return (this.aCompetenceIncluded = true)
        }

        if (!this.pCompetenceIncluded && baseCompetences.includes('P')) {
          return (this.pCompetenceIncluded = true)
        }

        if (!this.fCompetenceIncluded && baseCompetences.includes('F')) {
          return (this.fCompetenceIncluded = true)
        }

        if (!this.sCompetenceIncluded && baseCompetences.includes('S')) {
          return (this.sCompetenceIncluded = true)
        }

        return false
      })

      this.addToSelectedCompetenceCorridors(possibleChoices)
    }

    this.$refs.competenceEditorRef.checkValidity()
  }

  addToSelectedCompetenceCorridors (toBeAdded: CompetenceEvaluation[]): void {
    this.selectedCorridors.push(...toBeAdded
      .map(competence => {
        const value = Number(competence.value)
        // const rangeStartValue = value < 4 ? 1 + value : 3 + value
        const rangeStartValue = 5
        const rangeEndValue = rangeStartValue + 3
        const baseCompetences = this.$i18n.t('all-competences.' + competence.competence + '.shortCompetences').toString().split(',') as string[]

        baseCompetences.forEach(baseCompetence => {
          switch (baseCompetence) {
            case 'A': {
              this.aCompetenceIncluded = true
              break
            }
            case 'P': {
              this.pCompetenceIncluded = true
              break
            }
            case 'F': {
              this.fCompetenceIncluded = true
              break
            }
            case 'S': {
              this.sCompetenceIncluded = true
              break
            }
            default: {
              break
            }
          }
        })

        return {
          competence: competence.competence,
          range: [rangeStartValue, rangeEndValue]
        } as CompetenceCorridorForFrontend
      })
    )
  }

  emitChanges (updatedCorridors: CompetenceCorridorForFrontend[]): void {
    this.selectedCorridors = updatedCorridors
    this.transformCorridorsForBackend(updatedCorridors).then(data => {
      data.map(newCorridor => {
        newCorridor.id = this.internalModel.competences?.find(persistedCompetence => {
          return persistedCompetence.competence === newCorridor.competence
        })?.id
      })
      this.$emit('competences-changed', data)
    })
  }
}
