












































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import AutocompleteInput from '@/components/common/AutocompleteInput.vue'
import BaseForm from '@/components/forms/BaseForm.vue'
import { Form } from '@/store/commonFormInput/types'
import InputTypes from '@/common/formTypes'

@Component({
  components: { BaseForm, AutocompleteInput }
})
/*
This component contains a select (or autocomplete) input with another select input or a slider for a (skill)level.
It is used for languages and qualifications.
@group INPUT ELEMENTS
 */
export default class SelectWithLevel extends Vue {
  // unique identifier
  @Prop()
  id: number | string

  // if its writable (mostly used in combination with role rights)
  @Prop({ required: true, default: true })
  writeable: boolean

  // if its readonly (mostly used in combination with role rights)
  @Prop({ required: true, default: true })
  readonly: boolean

  // if true a select input is used, if false a autocomplete input
  @Prop()
  onlySelect: boolean

  // the options for the main input field
  @Prop()
  options: never[]

  // the options for the level input field
  @Prop()
  levels: never[]

  // an index to distinguish the input components when multiple are used
  @Prop()
  index: number

  // the label for the main input
  @Prop()
  label: string

  // the label for the level input
  @Prop({ default: '' })
  levelSelectLabel: string

  // the model used for the data
  @Prop()
  model: never

  // the key for the input fields
  @Prop()
  field: string

  // used in combination with the screen resolution if a medium column design should be used
  @Prop()
  md: boolean

  // if a slider should be used for the level input
  @Prop()
  slider: boolean

  // if a delete button should be shown next to the selection
  @Prop({ default: false })
  showDeleteButtonNextToSelection: boolean

  // the current state of the level input (if false an input by the user is missing)
  @Prop({ default: true })
  levelState: boolean

  // disable the level input
  @Prop({ default: false })
  noLevelInput: boolean

  // if it is the last input (there is no delete button for the last input)
  @Prop({ default: false })
  isLast: boolean

  internalModel: any = {
    level: 100
  }

  @Watch('model', { immediate: true })
  onModelChange (newValue: any): void {
    // if level === 0 it is ok. level has to be set otherwise it is 100
    if (!this.noLevelInput && newValue && (newValue.level === undefined || newValue.level === null) && this.slider) {
      newValue.level = 100
    }
    this.internalModel = newValue
  }

  get selectFields (): Form[] {
    const fields: Form[] = [
      {
        id: 'select_' + this.id + '_' + this.index,
        key: this.field,
        type: this.onlySelect ? InputTypes.SELECT : InputTypes.AUTOCOMPLETE,
        label: this.index >= 0 ? this.index + 1 + '. ' + this.label : this.label,
        options: this.options,
        required: false,
        deletable: this.internalModel[this.field] === '' && this.showDeleteButtonNextToSelection
      }
    ]

    if (this.internalModel[this.field] && !this.slider) {
      fields.push(
        {
          id: 'level_' + this.id + '_' + this.index,
          key: 'level',
          type: InputTypes.SELECT,
          label: this.levelSelectLabel,
          options: this.levels,
          required: true,
          deletable: true,
          state: this.levelState
        }
      )
    }

    return fields
  }

  removeValue (): void {
    this.$emit('remove', this.index)
    this.onInput()
  }

  onInput (): void {
    this.$emit('input', this.internalModel)
    this.$root.$emit('dismiss-alert')
  }
}
