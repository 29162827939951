



















































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import AutocompleteInput from '@/components/common/AutocompleteInput.vue'
import bootstrapBreakPoints from '@/common/breakpointsBootstrap'
import { Action, State } from 'vuex-class'
import { Qualification } from '@/store/types'
import { GET_ALL_SYNONYMS } from '@/store/user/actions.type'
import InfoBox from '@/components/hszg/InfoBox.vue'
import SubmitSuccess from '@/components/forms/SubmitSuccess.vue'
import SelectWithLevel from '@/components/forms/SelectWithLevel.vue'
@Component({
  components: { SelectWithLevel, SubmitSuccess, InfoBox, AutocompleteInput }
})
/*
The form component to add/edit qualification skills for a profile or job ad.
It contains the SelectWithLevel component (a autocomplete input for the qualifications and a select input for the levels).
@group FORMS
 */
export default class QualificationsEditorForm extends Vue {
  @State('currentScreenWidth') screenWidth: number

  @Action(GET_ALL_SYNONYMS)
  public getAllSynonyms: () => Promise<Array<Qualification>>

  // unique identifier
  @Prop()
  id: string

  // if its writable (mostly used in combination with role rights)
  @Prop()
  writeable: boolean

  // if its readonly (mostly used in combination with role rights)
  @Prop()
  readonly: boolean

  // input for the role rights if the user is able to write synonyms
  @Prop()
  synonymWrite: boolean

  // input for the role rights if the user is able to read synonyms
  @Prop()
  synonymRead: boolean

  // the qualifications available in the autocomplete input
  @Prop()
  qualifications: Qualification[]

  // if the qualifications currently load from the backend
  @Prop({ default: false })
  loadQualifications: boolean

  // disable the (skill)level input
  @Prop({ default: false })
  noLevelInput: boolean

  @Watch('qualifications', { immediate: true })
  onQualificationsChange (newValue: Qualification[]): void {
    this.talentQualifications = [...newValue]
    this.loadingQualifications = false
    this.qualificationInput(false)
  }

  @Watch('loadQualifications', { immediate: true })
  onQualificationsLoadChange (newValue: boolean): void {
    this.loadingQualifications = newValue
    if (!newValue) {
      this.loadSynonyms()
    }
  }

  talentQualifications: Qualification[] = []
  qualificationOptions: Qualification[] = []

  loadingSynonyms = false
  loadingQualifications = true

  duplicateFound = false

  get sliderMarks (): object {
    return {
      0: {
        label: this.$i18n.t('qualifications.basics').toString(),
        labelStyle: {
          color: this.$colors.darkGrey2,
          fontSize: '0.8em',
          transform: 'translateX(0%)',
          webkitTransform: 'translateX(0%)'
        }
      },
      100: {
        label: this.$i18n.t('qualifications.expert').toString(),
        labelStyle: {
          color: this.$colors.darkGrey2,
          fontSize: '0.8em',
          transform: 'translateX(-100%)',
          webkitTransform: 'translateX(-100%)'
        }
      }
    }
  }

  get md (): boolean {
    return this.screenWidth > bootstrapBreakPoints.md
  }

  mounted (): void {
    this.loadSynonyms()
    this.loadingQualifications = this.loadQualifications
  }

  loadSynonyms (): void {
    if (this.synonymRead) {
      this.loadingSynonyms = true
      this.getAllSynonyms().then(data => {
        this.qualificationOptions = [...data.map(quali => {
          return {
            ...quali,
            value: quali.synonym ? quali.synonym : '',
            text: quali.synonym
          }
        })]
        this.updateOptions()
      }).finally(() => {
        this.loadingSynonyms = false
      })
    }
  }

  checkForDuplicates (): void {
    this.duplicateFound = false
    this.talentQualifications.map((qualification, index) => {
      // reset the states
      if (!qualification.state) qualification.state = null

      // check for duplicates
      const duplicates = this.talentQualifications.filter(el => el.value.toLowerCase() === qualification.value.toLowerCase())
      if (duplicates.length > 1) {
        this.duplicateFound = true
        this.talentQualifications.splice(index, 1, { ...qualification, state: false })
      }
    })
  }

  emitQualifications (): void {
    this.checkForDuplicates()

    // get ids from existing options or delete ids from changed entries
    this.talentQualifications.forEach(qualification => {
      const option = this.qualificationOptions.find(option => option.value === qualification.value)
      if (option) qualification.synonymId = option.synonymId
      else qualification.synonymId = ''
    })

    // delete empty entries
    if (!this.duplicateFound) {
      const filtered = this.talentQualifications.filter(qualification => qualification.value !== '')
      this.$emit('qualificationsChanged', filtered)
    }
  }

  qualificationInput (emitQualifications: boolean): void {
    // add new input field
    if (!this.talentQualifications.length || this.talentQualifications[this.talentQualifications.length - 1].synonym !== '') {
      this.talentQualifications.push({ synonym: '', value: '' })
    }

    // use checkForDuplicates() to reset invalid states if the concerning input fields are changed
    if (this.duplicateFound) this.checkForDuplicates()

    this.talentQualifications = this.talentQualifications.map(qualification => {
      return {
        ...qualification,
        text: qualification.value,
        synonym: qualification.value
      }
    })
    this.updateOptions()
    if (emitQualifications) {
      this.emitQualifications()
    }
  }

  removeQualification (i: number): void {
    this.talentQualifications.splice(i, 1)
    this.qualificationInput(true)
    this.checkForDuplicates()
  }

  updateOptions (): void {
    // disable the already used input fields
    // usage of $set to keep reactivity
    this.qualificationOptions.map(q => this.$set(q, 'disabled', this.talentQualifications.map(tq => Object.values(tq).includes(q.value)).includes(true)))
  }
}
