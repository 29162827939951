




























import Vue from 'vue'
import Component from 'vue-class-component'
import { Form, KeyValue } from '@/store/commonFormInput/types'
import formTypes from '@/common/formTypes'
import BaseForm from '@/components/forms/BaseForm.vue'
import { Prop, Watch } from 'vue-property-decorator'
import { JobAdType } from '@/store/jobAd/types'
import Enums from '@/common/Enums'
import Unescape from '@/common/unescape'
import { Action } from 'vuex-class'
import { GET_PORTER_VALUES } from '@/store/jobAd/actions'

@Component({
  components: { BaseForm }
})
// Component for the first step of the job ad editor (company sets sector and responsibilities of the job)
// @group JOB AD EDITOR
export default class NetProductSector extends Vue {
  @Action(GET_PORTER_VALUES)
  public getPorterValues: () => Promise<Array<string>>

  @Prop()
  internalModel: JobAdType

  // is the form readonly (input user right)
  @Prop({ default: false })
  readonly: boolean

  // is the form writeable (input user right)
  @Prop({ default: false })
  writeable: boolean

  @Watch('internalModel', { immediate: true })
  onModelChanged (newValue: JobAdType): void {
    if (typeof newValue.porterValues !== 'undefined') {
      this.basicConditionModel.porterValues = newValue.porterValues
      this.basicConditionModel.responsibilityEmployees = newValue.responsibilityEmployees ? newValue.responsibilityEmployees : 'NOT_SPECIFIED'
      this.basicConditionModel.responsibilityBudget = newValue.responsibilityBudget ? newValue.responsibilityBudget : 'NOT_SPECIFIED'
      this.basicConditionModel.teamwork = newValue.teamwork ? newValue.teamwork : 'NOT_SPECIFIED'
      this.basicConditionModel.teamSize = this.basicConditionModel.teamwork === 'IN_TEAM' ? newValue.teamSize : null
    }
  }

  basicConditionModel: JobAdType = { porterValues: [] }
  porterValues: string[] = []

  get fields (): Form[] {
    return [
      {
        id: 'net-product-sector-checkboxes',
        key: 'porterValues',
        type: formTypes.CHECKBOXGROUP,
        label: this.$i18n.t('job-ad.net-product-sector-label').toString(),
        options: this.porterValues.map(key => {
          return {
            text: this.$i18n.te('job-ad.net-product-sectors.' + key) ? this.$i18n.t('job-ad.net-product-sectors.' + key).toString() : key,
            value: key
          } as KeyValue
        }),
        md: 12
      }
    ]
  }

  get jobDescriptionFields (): Form[] {
    return [
      {
        id: 'responsibilityEmployees',
        key: 'responsibilityEmployees',
        type: formTypes.RADIO,
        label: this.$i18n.t('job-ad.description.responsibilityEmployees-label').toString(),
        options: this.responsibilityEmployeesOptions,
        md: 12
      },
      {
        id: 'responsibilityBudget',
        key: 'responsibilityBudget',
        type: formTypes.RADIO,
        label: this.$i18n.t('job-ad.description.responsibilityBudget-label').toString(),
        options: this.responsibilityBudgetOptions,
        md: 12
      },
      {
        id: 'teamwork',
        key: 'teamwork',
        type: formTypes.RADIO,
        label: this.$i18n.t('job-ad.description.teamwork').toString(),
        options: this.teamworkOptions,
        md: this.internalModel.teamwork === 'IN_TEAM' ? 6 : 12
      },
      this.internalModel.teamwork === 'IN_TEAM' ? {
        id: 'teamSize',
        key: 'teamSize',
        type: formTypes.TEXT,
        label: this.$i18n.t('job-ad.description.teamSize').toString(),
        md: 6
      } : {
        id: 'empty',
        key: 'empty',
        type: formTypes.DUMMY
      }
    ]
  }

  get responsibilityEmployeesOptions (): KeyValue[] {
    return Enums.RESPONSIBILITY_EMPLOYEES.map(item => {
      return {
        value: item,
        text: this.$i18n.t('job-ad.description.responsibilitiesEmployees.' + item).toString()
      }
    })
  }

  get responsibilityBudgetOptions (): KeyValue[] {
    return Enums.RESPONSIBILITY_BUDGET.map(item => {
      return {
        value: item,
        text: this.$i18n.t('job-ad.description.responsibilitiesBudget.' + item).toString()
      }
    })
  }

  get teamworkOptions (): KeyValue[] {
    return Enums.TEAMWORK.map(item => {
      return {
        value: item,
        text: this.$i18n.t('job-ad.description.teamworkOptions.' + item).toString()
      }
    })
  }

  mounted (): void {
    this.getPorterValues().then(data => {
      this.porterValues = data
    })
    this.$nextTick(this.unescapeShy)
  }

  updated (): void {
    this.$nextTick(this.unescapeShy)
  }

  unescapeShy (): void {
    const root = document.getElementById('job-ad-basic-condition-form')
    if (root !== null) {
      Unescape.setSoftHyphensInLabels(root)
    }
  }
}
