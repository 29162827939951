

































































































































































































import { Component } from 'vue-property-decorator'
import Description from '@/components/forms/job-ad-editor/Description.vue'
import Matching from '@/components/forms/job-ad-editor/Matching.vue'
import ProgressSteps from '@/components/common/ProgressSteps.vue'
import { ProgressStep, SimpleServerResponse, User } from '@/store/types'
import { Action, Getter, State } from 'vuex-class'
import NetProductSector from '@/components/forms/job-ad-editor/NetProductSector.vue'
import { JobAdType, JobAdSpecs } from '@/store/jobAd/types'
import BaseForm from '@/components/forms/BaseForm.vue'
import formTypes from '@/common/formTypes'
import { GET_JOB_AD, GET_JOB_ADS, PUBLISH_JOB_AD, SAVE_JOB_AD } from '@/store/jobAd/actions'
import TextCreatorForSubmitSuccess from '@/components/forms/getHeadlineAndInfoForSubmitSuccess'
import ActivityDescription from '@/components/forms/job-ad-editor/ActivityDescription.vue'
import QualificationRequirement from '@/components/forms/job-ad-editor/QualificationRequirement.vue'
import Competences from '@/components/forms/job-ad-editor/Competences.vue'
import Rights from '@/common/rights'
import Enums from '@/common/Enums'
import { parseDateTime } from '@/common/datePicker'
import { hasRight } from '@/utils/rights'
import PreviewHandler from '@/components/common/PreviewHandler.vue'
import MetaComponent from '@/common/MetaComponent.vue'

@Component({
  components: { PreviewHandler, Competences, QualificationRequirement, ActivityDescription, BaseForm, NetProductSector, ProgressSteps, Description, Matching }
})
export default class JobAdEditor extends MetaComponent {
  @State('showDesktopVersion') showDesktopVersion: boolean
  @Getter('isCompanyLoggedIn') isCompanyLoggedIn: boolean
  @Getter('userGetter') userGetter: User
  @Action(SAVE_JOB_AD) saveJobAd: (jobAd: JobAdType) => Promise<JobAdType>
  @Action(PUBLISH_JOB_AD) setPublic: (jobAd: JobAdType) => Promise<SimpleServerResponse>
  @Action(GET_JOB_AD) getJobAd: (jobAdSpec: JobAdSpecs) => Promise<JobAdType>
  @Action(GET_JOB_ADS) getJobAds: (getOwn: boolean) => Promise<Array<JobAdType>>

  $refs!: {
    qualificationRequirement: QualificationRequirement;
  }

  jobAd: JobAdType = {
    title: '',
    publish: [],
    isPublic: false
  }

  steps = Enums.JOB_EDITOR_STEPS
  Enums = Enums
  activeStep = Enums.BASIC_CONDITION
  activeTo: number | null = null
  jobAdActiveTo = -1
  competencesValid = false
  publishJobAd = false
  showError = false
  writeable = false
  readonly = false
  createMode = true
  noProfile = false

  get jobProfileProgress (): ProgressStep[] {
    return [
      {
        index: 0,
        status: Enums.BASIC_CONDITION,
        text: this.$i18n.t('job-ad.basic-condition').toString(),
        icon: this.stepFulfilled(Enums.BASIC_CONDITION) ? 'check' : 'edit',
        clickable: true
      },
      {
        index: 1,
        status: Enums.ACTIVITY_DESCRIPTION,
        text: this.$i18n.t('job-ad.activities-description.activity-description').toString(),
        icon: this.stepFulfilled(Enums.ACTIVITY_DESCRIPTION) ? 'check' : 'edit',
        clickable: this.stepFulfilled(Enums.BASIC_CONDITION) || this.stepFulfilled(Enums.ACTIVITY_DESCRIPTION)
      },
      {
        index: 2,
        status: Enums.QUALIFICATION_REQUIREMENT,
        text: this.$i18n.t('job-ad.qualification-requirement').toString(),
        icon: this.stepFulfilled(Enums.QUALIFICATION_REQUIREMENT) ? 'check' : 'edit',
        clickable: this.stepFulfilled(Enums.ACTIVITY_DESCRIPTION) || this.stepFulfilled(Enums.QUALIFICATION_REQUIREMENT)
      },
      {
        index: 3,
        status: Enums.COMPETENCE_REQUIREMENT,
        text: this.$i18n.t('job-ad.competences.competence-requirement').toString(),
        icon: this.stepFulfilled(Enums.COMPETENCE_REQUIREMENT) ? 'check' : 'edit',
        clickable: this.stepFulfilled(Enums.QUALIFICATION_REQUIREMENT) || this.stepFulfilled(Enums.COMPETENCE_REQUIREMENT)
      }
    ]
  }

  get jobAdProgress (): ProgressStep[] {
    return [
      {
        index: 4,
        status: Enums.MATCHING,
        text: this.$i18n.t('job-ad.matching.matching').toString(),
        icon: 'none',
        clickable: this.stepFulfilled(Enums.COMPETENCE_REQUIREMENT)
      },
      {
        index: 5,
        status: Enums.PUBLISH_JOB_AD,
        text: this.$i18n.t('job-ad.description.publish-as-job-offer').toString(),
        icon: 'none',
        clickable: this.stepFulfilled(Enums.MATCHING)
      }
    ]
  }

  get progressBar (): ProgressStep[] {
    return [...this.jobProfileProgress, ...this.jobAdProgress]
  }

  get activeStepIndex (): number {
    return this.progressBar.findIndex(el => el.status === this.activeStep)
  }

  get textInputType (): string {
    return formTypes.TEXT
  }

  get isSaveButtonDisabled (): boolean {
    let isDisabled = this.jobAd.title === '' || this.jobAd.title === undefined

    if (this.activeStep === Enums.COMPETENCE_REQUIREMENT) {
      isDisabled = isDisabled || !this.competencesValid
    }

    if (this.activeStep === Enums.MATCHING) {
      isDisabled = isDisabled || (!this.jobAd.matchByQualifications && !this.jobAd.matchByCompetences)
    }

    return isDisabled
  }

  get canSaveAnyJobAd (): boolean {
    return hasRight(Rights.JOB_AD_WRITE)
  }

  get canReadOwnJobAd (): boolean {
    return hasRight(Rights.JOB_AD_OWN_READ)
  }

  get canWriteOwnJobAd (): boolean {
    return hasRight(Rights.JOB_AD_OWN_WRITE)
  }

  setJobAdStatus (statusId: string): void {
    this.activeStep = statusId
  }

  onBasicConditionChange (e: JobAdType): void {
    this.jobAd = { ...this.jobAd, ...e }
  }

  addCoursesQualificationsLanguages (newModel: JobAdType): void {
    this.jobAd.coursesOfStudy = newModel.coursesOfStudy ? newModel.coursesOfStudy?.length > 0 ? newModel.coursesOfStudy : undefined : undefined
    this.jobAd.qualifications = newModel.qualifications ? newModel.qualifications?.length > 0 ? newModel.qualifications : undefined : undefined
    this.jobAd.languages = newModel.languages ? newModel.languages?.length > 0 ? newModel.languages : undefined : undefined
  }

  addAccuracy (values: JobAdType): void {
    this.jobAd = { ...values }
    if (!values.matchByQualifications) {
      this.jobAd.strictQualificationMatching = false
    }
  }

  nextStep (): void {
    this.$root.$emit('load')
    // delete entries that are undefined
    // therefore create a copy of job ad model to prevent any layout changes before job is saved and view is updated
    const jobAdCopy: JobAdType = { ...this.jobAd }
    jobAdCopy.coursesOfStudy = undefined
    if (this.jobAd.coursesOfStudy !== undefined) {
      this.jobAd.coursesOfStudy.forEach(course => {
        if (course !== undefined) {
          if (jobAdCopy.coursesOfStudy === undefined) {
            jobAdCopy.coursesOfStudy = []
          }
          jobAdCopy.coursesOfStudy.push(course)
        }
      })

      this.jobAd.coursesOfStudy = this.jobAd.coursesOfStudy.filter(course => course)
    }

    this.jobAd.isOwn = !this.canSaveAnyJobAd
    this.jobAd.status = this.jobAd.publish ? Enums.JOB_AD_STATUS_PUBLISHED : Enums.JOB_AD_STATUS_COMPLETED_NOT_PUBLISHED
    const publish = this.jobAd.publish
    this.parseDateTimes(this.jobAd)

    // check if chosen languages have levels
    if (this.jobAd.languages && this.jobAd.languages.some(lang => lang.level === '')) {
      return
    }

    if (this.jobAd.teamwork !== 'IN_TEAM') {
      this.jobAd.teamSize = null
    }

    this.saveJobAd(this.jobAd).then(data => {
      if (data.jobAdId) {
        this.$router.push({ name: 'job-editor', params: { id: data.jobAdId } })
      }

      this.jobAd = { ...data, isOwn: !this.canSaveAnyJobAd, publish: publish }
      if (publish) {
        this.setPublic(this.jobAd).then(() => {
          this.$root.$emit('alert', this.$t('info.publish-success', { what: this.$tc('job-ad.job-offer', 1) }).toString(), '', false)
          this.getExistingJobAd(this.jobAd.jobAdId, undefined)
        }).catch(error => {
          const textCreator = new TextCreatorForSubmitSuccess<SimpleServerResponse, keyof SimpleServerResponse>(error, 'job-ad.save-messages.')
          this.$root.$emit('end-load')
          this.$root.$emit('alert', this.$i18n.t(textCreator.getHeadline()), this.$i18n.t(textCreator.getInfo()), true)
        })
      } else {
        this.$root.$emit('alert', this.$t('info.save-success', { what: this.$tc('job-ad.job-offer', 1) }).toString(), '', false)
        this.getExistingJobAd(this.jobAd.jobAdId, undefined)
      }
    }).catch((error) => {
      const textCreator = new TextCreatorForSubmitSuccess<SimpleServerResponse, keyof SimpleServerResponse>(error, 'job-ad.save-messages.')
      this.$root.$emit('end-load')
      this.$root.$emit('alert', this.$i18n.t(textCreator.getHeadline().toString()), this.$i18n.t(textCreator.getInfo().toString()), true)
    })
  }

  updateProgressBar (): void {
    this.progressBar.map(bar => {
      if (bar.status === this.activeStep) {
        this.$set(bar, 'icon', 'edit')
        this.$set(bar, 'clickable', true)
      }
    })

    if (this.jobAd.status !== Enums.JOB_AD_STATUS_INCOMPLETE) {
      this.activeTo = 4
      this.progressBar.map(bar => { this.$set(bar, 'clickable', true) })
    }

    if (this.jobAd.status === Enums.JOB_AD_STATUS_COMPLETED_NOT_PUBLISHED) {
      this.activeTo = 4
      this.jobAdActiveTo = 0
      this.$set(this.jobAdProgress, 0, { ...this.jobAdProgress[0], icon: this.stepFulfilled(Enums.MATCHING) && this.activeStep !== Enums.COMPETENCE_REQUIREMENT ? 'check' : 'edit' })
      this.$set(this.jobAdProgress, 1, { ...this.jobAdProgress[1], icon: 'edit' })
    }

    if (this.jobAd.status === Enums.JOB_AD_STATUS_PUBLISHED) {
      this.activeTo = 4
      this.jobAdActiveTo = 1
      this.$set(this.jobAdProgress, 0, { ...this.jobAdProgress[0], icon: 'check' })
      this.$set(this.jobAdProgress, 1, { ...this.jobAdProgress[1], icon: 'check' })
    }
  }

  mounted (): void {
    this.$root.$emit('load')
    const id = this.$route.params.id
    if (id) {
      this.getExistingJobAd(id, undefined)
    } else {
      this.createMode = true
      // check if company has a draft left
      if (this.isCompanyLoggedIn) {
        this.getJobAds(true).then(data => {
          const incomplete = data.filter(jobAd => {
            return jobAd.status === Enums.JOB_AD_STATUS_INCOMPLETE
          })

          // if there is a draft but we have no rights to display it -> show error
          if (incomplete.length > 0) {
            if (this.canReadOwnJobAd && incomplete[0].jobAdId) {
              this.getExistingJobAd(incomplete[0].jobAdId, incomplete[0])
            } else {
              this.showError = true
            }
          } else {
            // if there is no draft but we cannot write to server -> show error
            if (this.canWriteOwnJobAd) {
              this.jobAd = {
                title: '',
                companyId: undefined
              }
              this.readonly = false
              this.writeable = true
            } else {
              this.showError = true
            }
          }
        }).catch(error => {
          if (error.status === 409) {
            this.noProfile = true
          }
        }).finally(() => {
          this.$root.$emit('end-load')
        })
      } else {
        // employees are not allowed to create new job ads
        this.showError = true
        this.$root.$emit('end-load')
      }
    }
  }

  /**
   *  if an id was submitted or there is a draft available -> we are in edit mode
   *  get the jobAd that is references by the submitted id
   *  show errors if it is not possible due to missing rights
   */
  private getExistingJobAd (id: string | undefined, jobAdDraft?: JobAdType): void {
    const jobAdSpecs: JobAdSpecs = { id: '', isOwn: false }
    jobAdSpecs.id = id
    this.createMode = false
    // check rights for a company
    if (this.isCompanyLoggedIn) {
      if (this.canReadOwnJobAd) {
        jobAdSpecs.isOwn = true
      } else {
        this.showError = true
      }
      // set rights for form inputs
      this.readonly = this.canReadOwnJobAd && !this.canWriteOwnJobAd
      this.writeable = this.canWriteOwnJobAd
    } else {
      // check rights for an employee
      if (this.canSaveAnyJobAd) {
        jobAdSpecs.isOwn = false
      } else {
        this.showError = true
      }
      // set rights for form inputs
      this.readonly = this.canSaveAnyJobAd
      this.writeable = this.canSaveAnyJobAd
    }

    // get the job ad
    if (jobAdDraft === undefined) {
      this.getJobAd(jobAdSpecs).then(jobAd => {
        this.setUpPage(jobAd)
      }).catch(() => {
        this.showError = true
      }).finally(() => {
        this.$root.$emit('end-load')
      })
    } else {
      this.setUpPage(jobAdDraft)
    }
  }

  setUpPage (jobAdFromServer: JobAdType): void {
    this.jobAd = jobAdFromServer
    this.$set(this.jobAd, 'coursesOfStudy', jobAdFromServer.coursesOfStudy ? jobAdFromServer.coursesOfStudy : [])
    this.$set(this.jobAd, 'qualifications', jobAdFromServer.qualifications ? jobAdFromServer.qualifications : [])
    this.$set(this.jobAd, 'publish', (jobAdFromServer.status === 'PUBLISHED' || jobAdFromServer.publish))
    this.parseToDate(jobAdFromServer)
    if (this.isCompanyLoggedIn) {
      this.jobAd.companyId = undefined
    }
    this.setActiveStep()
    this.updateProgressBar()
    this.$root.$emit('end-load')
  }

  parseDateTimes (jobAd: JobAdType): void {
    this.$set(this.jobAd, 'startDate', parseDateTime(jobAd.startDate))
    this.$set(this.jobAd, 'endDate', parseDateTime(jobAd.endDate))
    this.$set(this.jobAd, 'endPublication', parseDateTime(jobAd.endPublication))
  }

  parseToDate (jobAd: JobAdType): void {
    this.$set(this.jobAd, 'startDate', jobAd.startDate ? new Date(jobAd.startDate) : undefined)
    this.$set(this.jobAd, 'endDate', jobAd.endDate ? new Date(jobAd.endDate) : undefined)
    this.$set(this.jobAd, 'endPublication', jobAd.endPublication ? new Date(jobAd.endPublication) : undefined)
  }

  // TODO replace with backend service call when available
  setActiveStep (): void {
    if (!this.stepFulfilled(Enums.BASIC_CONDITION)) {
      this.activeStep = Enums.BASIC_CONDITION
      return
    }

    if (!this.stepFulfilled(Enums.ACTIVITY_DESCRIPTION)) {
      this.activeStep = Enums.ACTIVITY_DESCRIPTION
      return
    }

    if (!this.stepFulfilled(Enums.QUALIFICATION_REQUIREMENT)) {
      this.activeStep = Enums.QUALIFICATION_REQUIREMENT
      return
    }

    if (!this.stepFulfilled(Enums.COMPETENCE_REQUIREMENT)) {
      this.activeStep = Enums.COMPETENCE_REQUIREMENT
      return
    }

    if (!this.stepFulfilled(Enums.MATCHING) || this.activeStep === Enums.COMPETENCE_REQUIREMENT) {
      this.activeStep = Enums.MATCHING
      return
    }

    if (!this.descriptionFulfilled) {
      this.activeStep = Enums.PUBLISH_JOB_AD
      return
    }

    this.activeStep = Enums.PUBLISH_JOB_AD
  }

  get saveButtonText (): string {
    return this.$i18n.t('actions.save') + (this.publishJobAd && this.jobAd.publish ? ' & ' + this.$i18n.t('actions.publish') : (this.activeStepIndex === this.progressBar.length - 1 || !this.stepFulfilled(this.activeStep) ? '' : ' & ' + this.$i18n.t('actions.next')))
  }

  stepFulfilled (step: string): boolean {
    switch (step) {
      case Enums.BASIC_CONDITION:
        return !(this.jobAd.porterValues === undefined || this.jobAd.porterValues.length < 1)
      case Enums.ACTIVITY_DESCRIPTION:
        return !(this.jobAd.activities === undefined || this.jobAd.activities.length < 5)
      case Enums.QUALIFICATION_REQUIREMENT:
        return !(this.jobAd.qualifications === undefined || this.jobAd.qualifications.length < 1)
      case Enums.COMPETENCE_REQUIREMENT:
        return !(this.jobAd.competences === undefined || this.jobAd.competences.length < 8)
      case Enums.MATCHING:
      {
        const accuracyCompetences: number | undefined = this.jobAd.accuracyCompetences
        const accuracyQualifications: number | undefined = this.jobAd.accuracyQualifications
        const matchByCompetences: boolean | undefined = this.jobAd.matchByCompetences
        const matchByQualifications: boolean | undefined = this.jobAd.matchByQualifications
        return (matchByQualifications !== undefined && matchByCompetences !== undefined) &&
          (matchByQualifications || matchByCompetences) &&
          (accuracyCompetences !== undefined && accuracyCompetences >= 0 && accuracyQualifications !== undefined && accuracyCompetences >= 0)
      }
      default: return false
    }
  }

  get descriptionFulfilled (): boolean {
    return !(this.jobAd.description === null || this.jobAd.description === '' || this.jobAd.startDate === undefined || this.jobAd.location === undefined || this.jobAd.location === '')
  }

  public showPreview (): void {
    this.$nextTick(() => {
      const lang = this.$i18n.locale.toString()
      const routeData = this.$router.resolve({ name: 'job-ad-preview', params: { id: this.jobAd.jobAdId ? this.jobAd.jobAdId : '', preview: 'preview' } })
      const target = window.location.origin + '/' + lang + routeData.href
      window.open(target, '_blank')
    })
  }
}
