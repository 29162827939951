























































































































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import SubmitSuccess from '@/components/forms/SubmitSuccess.vue'
import { JobAdType } from '@/store/jobAd/types'
import { Form } from '@/store/commonFormInput/types'
import InputTypes from '@/common/formTypes'
import BaseForm from '@/components/forms/BaseForm.vue'
import { GET_MATCHING_RESULTS_ESTIMATION } from '@/store/jobAd/actions'
import { Action } from 'vuex-class'
import ErrorAlert from '@/components/common/ErrorAlert.vue'
import InfoBox from '@/components/hszg/InfoBox.vue'

@Component({
  components: { SubmitSuccess, BaseForm, ErrorAlert, InfoBox }
})
// Component for the fifth step of the job ad editor (settings for matching)
// @group JOB AD EDITOR
export default class Matching extends Vue {
  @Prop()
  internalModel: JobAdType

  // is the form readonly (input user right)
  @Prop({ default: false })
  readonly: boolean

  // is the form writeable (input user right)
  @Prop({ default: false })
  writeable: boolean

  @Watch('internalModel', { immediate: true })
  onModelChanged (newValue: JobAdType): void {
    this.jobAd = { ...newValue }
  }

  @Action(GET_MATCHING_RESULTS_ESTIMATION) getMatchingResultEstimation: (jobAd: JobAdType) => Promise<number>

  jobAd: JobAdType = {}

  marks = (val: number): object => {
    if (val % 25 === 0) {
      return {
        label: `${val}%`
      }
    } else {
      return {
        label: ''
      }
    }
  }

  dragging = false
  matchingResults = '0'
  resultsLoading = false

  get matchQualificationsCheckbox (): Form[] {
    return [
      {
        id: 'match-by-qualification',
        key: 'matchByQualifications',
        type: InputTypes.CHECKBOX,
        label: this.$i18n.t('job-ad.matching.include-qualification-matching').toString(),
        md: 6
      },
      {
        id: 'strict-qualification-matching',
        key: 'strictQualificationMatching',
        type: InputTypes.CHECKBOX,
        label: this.$i18n.t('job-ad.matching.include-strict-matching').toString(),
        md: 6,
        disabled: this.disableQualificationsSlider
      }
    ]
  }

  get matchByCompetencesCheckbox (): Form[] {
    return [
      {
        id: 'match-by-competences',
        key: 'matchByCompetences',
        type: InputTypes.CHECKBOX,
        label: this.$i18n.t('job-ad.matching.include-competence-matching').toString(),
        md: 12
      }
    ]
  }

  get disableCompetenceSlider (): boolean {
    return this.readonly || !this.jobAd.matchByCompetences
  }

  get disableQualificationsSlider (): boolean {
    return this.readonly || !this.jobAd.matchByQualifications
  }

  emitValues (id: string, dragging: boolean): void {
    if (!dragging) {
      if (!this.jobAd.matchByCompetences && !this.jobAd.matchByQualifications) {
        switch (id) {
          case 'match-by-competences': {
            this.jobAd.matchByQualifications = true
            break
          }
          case 'match-by-qualification': {
            this.jobAd.matchByCompetences = true
            break
          }
        }
      }
      this.$emit('input', this.jobAd)
      this.estimateMatchingResults()
    }
  }

  estimateMatchingResults (): void {
    this.resultsLoading = true
    this.getMatchingResultEstimation(this.jobAd).then(data => {
      this.resultsLoading = false
      this.matchingResults = data.toString()
    }).catch(error => {
      if (error !== 'CANCELLED') {
        this.matchingResults = this.$t('warning.service-not-available').toString()
      }
    }).finally(() => {
      this.resultsLoading = false
    })
  }

  mounted (): void {
    const ids = ['form-match-by-competences', 'form-match-by-qualification', 'form-strict-qualification-matching']

    ids.forEach(id => {
      const formGroupLabels = document.getElementById(id)?.getElementsByTagName('label')

      if (formGroupLabels && formGroupLabels.item(0)) {
        const label = formGroupLabels.item(0) as HTMLElement
        label.style.display = 'none'
        const inputGroup = label.nextSibling as HTMLElement
        inputGroup.style.marginLeft = '0'
        inputGroup.style.paddingLeft = '5px'
      }
    })
  }

  created (): void {
    this.estimateMatchingResults()
  }
}
