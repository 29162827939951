























































































































































































































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import ClickableIcon from '@/components/common/ClickableIcon.vue'
import CustomSelect from '@/components/common/CustomSelect.vue'
import Unescape from '@/common/unescape'
import { CompetenceCorridorForFrontend, MetaProfile } from '@/store/kode/types'
import { State } from 'vuex-class'
import { TableFields } from '@/store/types'
import { Form, KeyValue } from '@/store/commonFormInput/types'
import InputTypes from '@/common/formTypes'
import BaseForm from '@/components/forms/BaseForm.vue'
import Enums from '@/common/Enums'
@Component({
  components: { CustomSelect, ClickableIcon, BaseForm }
})
/*
The editor component for the competences. Allows the user to add different competences and set their corridors.
@group FORMS
 */
export default class CompetencesEditor extends Vue {
  @State('showDesktopVersion') showDesktopVersion: boolean
  @State('currentScreenWidth') screenWidth: number

  // is the form readonly (input user right)
  @Prop({ default: false })
  readonly: boolean

  // is the form writeable (input user right)
  @Prop({ default: true })
  writeable: boolean

  // possibility to add a help text for the user
  @Prop()
  helpText: object

  // minimal number of competences the user should select
  @Prop({
    required: false,
    default: 12
  })
  minCount: number

  // maximal number of competences the user should select
  @Prop({
    required: false,
    default: 12
  })
  maxCount: number

  // the default minimum value for a new corridor
  @Prop({
    required: false,
    default: 2
  })
  newCorridorMinValue: number

  // the default maximum value for a new corridor
  @Prop({
    required: false,
    default: 11
  })
  newCorridorMaxValue: number

  // the default minimum difference (length) for a new corridor
  @Prop({
    required: false,
    default: 2
  })
  corridorMinDiff: number

  // the default maximum difference (length) for a new corridor
  @Prop({
    required: false,
    default: 4
  })
  corridorMaxDiff: number

  // minimum for the slider
  @Prop({
    required: false,
    default: 1
  })
  sliderMin: number

  // maximum for the slider
  @Prop({
    required: false,
    default: 12
  })
  sliderMax: number

  // if the component is used in the job editor
  @Prop({
    required: false,
    default: false
  })
  jobAdEditor: boolean

  // marks for the vue-slider component
  @Prop({ required: false })
  marks: number[] | undefined

  // a watched prop for the used competences and according corridors
  @Prop({ required: false })
  corridors: Array<CompetenceCorridorForFrontend>

  @Watch('corridors', { immediate: true })
  onCorridorChange (newValue: Array<CompetenceCorridorForFrontend>): void {
    this.items = newValue
    this.disableAlreadyAssignedCompetences()
    this.checkValidity()
  }

  // a watched prop for the name of the meta profile
  @Prop({ required: false })
  profileName: string | null

  // a watched prop for the type of the meta profile
  @Prop({ required: false })
  profileType: string | null

  @Watch('profileName', { immediate: true })
  onProfileNameChange (newValue: string): void {
    this.metaData.name = newValue
  }

  @Watch('profileType', { immediate: true })
  onProfileTypeChange (newValue: string): void {
    this.metaData.type = newValue
  }

  get metaDataFields (): Form[] {
    return [
      {
        id: 'profile-name',
        key: 'name',
        type: InputTypes.TEXT,
        label: this.$t('target-profiles.profile-name').toString(),
        required: true
      },
      {
        id: 'profile-type',
        key: 'type',
        type: InputTypes.SELECT,
        label: this.$t('target-profiles.type').toString(),
        required: true,
        options: this.profileTypes
      }
    ]
  }

  get fields (): TableFields[] {
    return [
      { key: 'delete', label: '' },
      { key: 'competence', label: this.$i18n.t('target-profiles.competence').toString() },
      { key: 'competence-short', label: this.$i18n.t('target-profiles.base-competence').toString() },
      { key: 'range', label: this.$i18n.t('target-profiles.corridor').toString() }
    ]
  }

  get profileTypes (): KeyValue[] {
    return Enums.TARGET_PROFILE_TYPES.map(key => {
      return {
        value: key,
        text: this.$i18n.t('target-profiles.TARGET_PROFILE_TYPES.' + key).toString()
      } as KeyValue
    })
  }

  items: CompetenceCorridorForFrontend[] = []
  modelForTableHeadline: number[] = [this.sliderMin, this.sliderMax]
  sortBy = 'competence'
  metaData: MetaProfile = { name: '', type: 'JOB_FAMILY', competenceCorridors: [] }

  newCompetence: string | null = null
  competenceSelectionState: boolean | null = null
  newMinValue: number = this.newCorridorMinValue
  newMaxValue: number = this.newCorridorMinValue + this.corridorMaxDiff - 1
  newCorridor = [this.newMinValue, this.newMaxValue]

  enoughCompetencesAdded = false
  competenceAlreadyInserted = false

  competences: KeyValue[] = []

  deleteEntry (competenceToDelete: CompetenceCorridorForFrontend): void {
    const indexOfItemToDelete = this.items.findIndex((competence) => {
      return competence.competence === competenceToDelete.competence
    })
    this.items.splice(indexOfItemToDelete, 1)
    this.checkValidity()
    this.disableAlreadyAssignedCompetences()
    this.$emit('competences-changed', this.items)
  }

  addCompetence (event: Event): void {
    event.preventDefault()
    const formValid = (document.getElementById('add-competence-form') as HTMLFormElement).checkValidity()

    this.items.forEach(corridor => {
      if (corridor.competence === this.newCompetence) {
        this.competenceAlreadyInserted = true
      }
    })

    this.competenceSelectionState = this.newCompetence !== null && !this.competenceAlreadyInserted

    if (formValid && this.competenceSelectionState && this.newCompetence !== null) {
      this.items.push({
        competence: this.newCompetence,
        range: this.newCorridor
      })
      this.newCompetence = null
      this.newCorridor = [this.newMinValue, this.newMaxValue]

      this.checkValidity()
      this.disableAlreadyAssignedCompetences()
      this.$emit('competences-changed', this.items)
    }
  }

  public checkValidity (): void {
    this.enoughCompetencesAdded = this.items.length >= this.minCount && this.items.length <= this.maxCount
    this.$emit('validity', this.enoughCompetencesAdded)
  }

  gotoNext (): void {
    this.$emit('next')
  }

  disableAlreadyAssignedCompetences (): void {
    this.competences = this.competences.map(competence => {
      competence.disabled = this.items.map(item => {
        return Object.values(item).includes(competence.value)
      }).includes(true)
      return competence
    })
  }

  mounted (): void {
    this.competences = Object.keys(this.$i18n.t('all-competences')).map(key => {
      return {
        value: key,
        text: this.$i18n.t('all-competences.' + key + '.title').toString()
      } as KeyValue
    })

    this.disableAlreadyAssignedCompetences()

    const root = document.getElementById('add-competence-form')
    if (root !== null) {
      Unescape.setSoftHyphensInLabels(root)
    }
  }
}
