































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import BaseForm from '@/components/forms/BaseForm.vue'
import { Prop, Watch } from 'vue-property-decorator'
import { CourseOfStudy, JobAdType } from '@/store/jobAd/types'
import { Form } from '@/store/commonFormInput/types'
import formTypes from '@/common/formTypes'
import { Action, Getter, State } from 'vuex-class'
import { GET_COURSES_OF_STUDY } from '@/store/jobAd/actions'
import QualificationsEditorForm from '@/components/forms/QualificationsEditorForm.vue'
import { Qualification, User } from '@/store/types'
import Rights from '@/common/rights'
import { hasRight } from '@/utils/rights'
import SelectWithLevel from '@/components/forms/SelectWithLevel.vue'
import { Language } from '@/store/student/types'
import LanguagesEditorForm from '@/components/forms/LanguagesEditorForm.vue'

@Component({
  components: { LanguagesEditorForm, SelectWithLevel, QualificationsEditorForm, BaseForm }
})
// Component for the third step of the job ad editor (company sets qualifications, languages, etc)
// @group JOB AD EDITOR
export default class QualificationRequirement extends Vue {
  @State('currentScreenWidth') screenWidth: number

  @Prop()
  internalModel: JobAdType

  // is the form readonly (input user right)
  @Prop({ default: false })
  readonly: boolean

  // is the form writeable (input user right)
  @Prop({ default: false })
  writeable: boolean

  @Getter('userGetter') userGetter: User

  @Watch('internalModel', { immediate: true })
  onModelChanged (newValue: JobAdType): void {
    this.updateCoursesOfStudy(newValue, false)
    const newQualifications = newValue.qualifications ? [...newValue.qualifications] : []
    this.mustQualifications = newQualifications.filter(q => q.relevance === 'MUSS').map(qualification => {
      return {
        ...qualification,
        value: qualification.synonym ? qualification.synonym : '',
        text: qualification.synonym
      }
    })
    this.desiredQualifications = newQualifications.filter(q => q.relevance === 'SOLL').map(qualification => {
      return {
        ...qualification,
        value: qualification.synonym ? qualification.synonym : '',
        text: qualification.synonym
      }
    })
    this.optionalQualifications = newQualifications.filter(q => q.relevance === 'KANN').map(qualification => {
      return {
        ...qualification,
        value: qualification.synonym ? qualification.synonym : '',
        text: qualification.synonym
      }
    })

    const newLanguages = newValue.languages ? [...newValue.languages] : []
    this.languages[0] = newLanguages.filter(l => l.relevance === 'MUSS').map(lang => {
      return {
        ...lang,
        value: lang.language ? lang.language : ''
      }
    })
    this.languages[1] = newLanguages.filter(l => l.relevance === 'SOLL').map(lang => {
      return {
        ...lang,
        value: lang.language ? lang.language : ''
      }
    })
    this.languages[2] = newLanguages.filter(l => l.relevance === 'KANN').map(lang => {
      return {
        ...lang,
        value: lang.language ? lang.language : ''
      }
    })
  }

  @Action(GET_COURSES_OF_STUDY)
  getAllCourses: () => Promise<Array<CourseOfStudy>>

  languages: Language[][] = [[{ value: '', level: '' }], [{ value: '', level: '' }], [{ value: '', level: '' }]]
  invalidLanguageLevels: boolean[][] = [[], [], []]

  public checkLanguageLevels (): void {
    this.languages.forEach((languagesArray, index) => {
      if (languagesArray.some(el => el.language !== '' && el.level === '')) {
        languagesArray = languagesArray.map(lang => ({ ...lang, state: lang.level !== '' }))
        languagesArray.forEach((el, i) => {
          this.$set(this.invalidLanguageLevels[index], i, !(el.value !== '' && el.level === ''))
        })
      }
    })
  }

  public setLanguages (updatedValues: Array<Language>, relevance: string): void {
    const languages = updatedValues.map<Language>((lang: Language) => {
      lang.relevance = relevance
      lang.language = lang.value
      return lang
    })

    switch (relevance) {
      case 'MUSS': {
        this.languages.splice(0, 1, languages)
        break
      }

      case 'SOLL': {
        this.languages.splice(1, 1, languages)
        break
      }

      case 'KANN': {
        this.languages.splice(2, 1, languages)
        break
      }
    }

    this.returnModelsAsArray()
  }

  get coursesOfStudyFields (): Array<Form> {
    return [
      {
        id: 'coursesOfStudy',
        key: 'coursesOfStudy',
        type: formTypes.MULTISELECT,
        label: this.$tc('student.cv-data.degree', 2).toString() + ' ' + this.$t('multiple-selection').toString(),
        options: this.allCoursesOfStudy.map(course => {
          return {
            ...course,
            value: course.id,
            name: this.$i18n.locale === 'de' ? course.deName : course.enName
          }
        }),
        required: false,
        fullWidth: false,
        md: 12
      }
    ]
  }

  get canWriteSynonyms (): boolean {
    return hasRight(Rights.SYNONYM_WRITE)
  }

  get canReadSynonyms (): boolean {
    return hasRight(Rights.SYNONYMS_READ)
  }

  private selectedCoursesOfStudy: JobAdType = { }
  private allCoursesOfStudy: CourseOfStudy[] = []
  private mustQualifications: Qualification[] = []
  private desiredQualifications: Qualification[] = []
  private optionalQualifications: Qualification[] = []

  public setQualifications (updatedValues: Array<Qualification>, relevance: string): void {
    const qualifications = updatedValues.map<Qualification>((qualification: Qualification) => {
      qualification.relevance = relevance
      qualification.synonym = qualification.value
      qualification.text = qualification.value
      return qualification
    })

    switch (relevance) {
      case 'MUSS': {
        this.mustQualifications = [...qualifications]
        break
      }

      case 'SOLL': {
        this.desiredQualifications = [...qualifications]
        break
      }

      case 'KANN': {
        this.optionalQualifications = [...qualifications]
        break
      }
    }

    this.returnModelsAsArray()
  }

  /**
   * Propagate each change of value to the parent component via Input-event
   */
  public returnModelsAsArray (): void {
    const returnObject: JobAdType = {}
    returnObject.coursesOfStudy = this.selectedCoursesOfStudy.coursesOfStudy ? this.selectedCoursesOfStudy.coursesOfStudy : []
    returnObject.qualifications = [...this.mustQualifications, ...this.desiredQualifications, ...this.optionalQualifications]
    returnObject.languages = [...this.languages[0], ...this.languages[1], ...this.languages[2]]
    this.$emit('input', returnObject)
  }

  updateCoursesOfStudy (model: JobAdType, emit: boolean): void {
    let courses = model.coursesOfStudy ? model.coursesOfStudy : []
    courses = courses.map(course => {
      return {
        ...course,
        value: course.id,
        name: this.$i18n.locale === 'de' ? course.deName : course.enName
      }
    })

    this.$set(this.selectedCoursesOfStudy, 'coursesOfStudy', courses)
    if (emit) {
      this.returnModelsAsArray()
    }
  }

  mounted (): void {
    this.getAllCourses().then(data => {
      this.allCoursesOfStudy = data
    })
  }
}
