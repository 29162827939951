var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"section"},[_c('b-col',{attrs:{"cols":"12"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('job-ad.matching.intro'))+" ")])])],1),_c('b-row',{staticClass:"section"},[_c('b-col',[_c('base-form',{attrs:{"fields":_vm.matchByCompetencesCheckbox,"model":_vm.jobAd,"readonly":_vm.readonly,"writeable":_vm.writeable},on:{"input":function($event){return _vm.emitValues($event, false)}}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"form-col mb-5",attrs:{"id":"competence-slider-formgroup","label-cols-sm":"12","label-cols-md":"3","label":_vm.$t('job-ad.matching.competence-requirement'),"label-for":"competence-slider"}},[_c('vue-slider',{staticClass:"mr-3 float-left",style:({width: '95%'}),attrs:{"id":"competence-slider","contained":true,"height":"14px","min":0,"max":100,"process":true,"enable-cross":false,"interval":5,"marks":_vm.marks,"tooltip":"none","disabled":_vm.disableCompetenceSlider},on:{"change":function($event){return _vm.emitValues('', _vm.dragging)},"drag-start":function($event){_vm.dragging = true},"drag-end":function () { _vm.dragging = false; _vm.emitValues('', false) }},scopedSlots:_vm._u([{key:"dot",fn:function(ref){
var value = ref.value;
var focus = ref.focus;
return [_c('div',{class:['custom-dot', { focus: focus }]})]}},{key:"step",fn:function(ref){
var label = ref.label;
var active = ref.active;
return [_c('div',{class:['custom-step', { active: active }]})]}}]),model:{value:(_vm.jobAd.accuracyCompetences),callback:function ($$v) {_vm.$set(_vm.jobAd, "accuracyCompetences", $$v)},expression:"jobAd.accuracyCompetences"}})],1)],1)],1),_c('b-row',{staticClass:"section"},[_c('b-col',[_c('base-form',{attrs:{"fields":_vm.matchQualificationsCheckbox,"model":_vm.jobAd,"readonly":_vm.readonly,"writeable":_vm.writeable},on:{"input":function($event){return _vm.emitValues($event, false)}}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"form-col",attrs:{"id":"qualification-slider-formgroup","label-cols-sm":"12","label-cols-md":"3","label":_vm.$t('job-ad.matching.qualification-requirement'),"label-for":"qualification-slider"}},[_c('vue-slider',{staticClass:"mr-3 float-left",style:({width: '95%'}),attrs:{"id":"qualification-slider","contained":true,"height":"14px","min":0,"max":100,"process":true,"enable-cross":false,"interval":5,"marks":_vm.marks,"tooltip":"none","disabled":_vm.disableQualificationsSlider},on:{"change":function($event){return _vm.emitValues('', _vm.dragging)},"drag-start":function($event){_vm.dragging = true},"drag-end":function () { _vm.dragging = false; _vm.emitValues('', false) }},scopedSlots:_vm._u([{key:"dot",fn:function(ref){
var value = ref.value;
var focus = ref.focus;
return [_c('div',{class:['custom-dot', { focus: focus }]})]}},{key:"step",fn:function(ref){
var label = ref.label;
var active = ref.active;
return [_c('div',{class:['custom-step', { active: active }]})]}}]),model:{value:(_vm.jobAd.accuracyQualifications),callback:function ($$v) {_vm.$set(_vm.jobAd, "accuracyQualifications", $$v)},expression:"jobAd.accuracyQualifications"}})],1)],1)],1),_c('b-row',{staticClass:"section"},[_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"md":"9"}},[_c('div',{staticClass:"form-row form-group form-col",attrs:{"id":"group-estimated-matching-results"}},[_c('label',{staticClass:"col-sm-12 col-md-4 col-form-label"},[_c('span',{staticClass:"font-italic font-weight-bolder"},[_vm._v(_vm._s(_vm.$t('job-ad.matching.estimated-matching-results')))])]),_c('div',{staticClass:"bv-no-focus-ring col"},[_c('div',{staticClass:"d-flex h-100 align-items-center"},[_c('span',{staticClass:"mr-1 font-italic font-weight-bolder",staticStyle:{"font-size":"1.2rem"}},[_vm._v(" "+_vm._s(_vm.matchingResults)+" ")]),(_vm.resultsLoading)?_c('b-spinner',{attrs:{"variant":"success","small":""}}):_vm._e()],1)])])]),_c('b-col',{attrs:{"cols":"12"}},[_c('info-box',[_vm._v(" "+_vm._s(_vm.$t('job-ad.matching.matching-results-hint'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }