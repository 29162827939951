





































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import CustomSelect from '@/components/common/CustomSelect.vue'
import DatePicker from 'vue2-datepicker'
import { Action } from 'vuex-class'
import Unescape from '@/common/unescape'
import { GET_JOB_TYPES } from '@/store/jobAd/actions'
import { Form, KeyValue } from '@/store/commonFormInput/types'
import InputTypes from '@/common/formTypes'
import { JobAdType } from '@/store/jobAd/types'
import BaseForm from '@/components/forms/BaseForm.vue'
import Enums from '@/common/Enums'

@Component({
  components: { BaseForm, CustomSelect, DatePicker }
})
// Component for the sixth and final step of the job ad editor (company published the job ad)
// @group JOB AD EDITOR
export default class Description extends Vue {
  @Action(GET_JOB_TYPES)
  public getJobTypes: () => Promise<string[]>

  // is the form readonly (input user right)
  @Prop({ default: false })
  readonly: boolean

  // is the form writeable (input user right)
  @Prop({ default: false })
  writeable: boolean

  @Prop()
  initialModel: JobAdType

  @Watch('initialModel', { immediate: false })
  onModelChanged (newValue: JobAdType): void {
    this.internalModel = newValue
    this.internalModel.startDate = newValue.startDate ? new Date(newValue.startDate) : null
    this.internalModel.endDate = newValue.endDate ? new Date(newValue.endDate) : null
    this.internalModel.endPublication = newValue.endPublication ? new Date(newValue.endPublication) : null
  }

  internalModel: JobAdType = {
    jobType: '',
    location: '',
    workingHours: 'FULL_TIME',
    startDate: undefined,
    endDate: undefined,
    endPublication: undefined,
    description: '',
    responsibilityBudget: 'NO_BUDGET_RESPONSIBILITY',
    responsibilityEmployees: 'NOT_SPECIFIED',
    publish: false,
    isPublic: false,
    title: ''
  }

  jobTypes: string[] = []

  get workingHours (): KeyValue[] {
    return Enums.WORKING_HOURS.map(item => {
      return {
        value: item,
        text: this.$i18n.t('job-ad.description.WORKING_HOURS.' + item).toString()
      } as KeyValue
    })
  }

  get jobTypeOptions (): KeyValue[] {
    return this.jobTypes.map(item => {
      return {
        value: item,
        text: this.$i18n.t('job-ad.description.job-types.' + item).toString()
      } as KeyValue
    })
  }

  get isValidForPublishing (): boolean {
    const isValid = this.internalModel.startDate !== undefined && this.internalModel.startDate !== null &&
      this.internalModel.location !== '' && this.internalModel.location !== undefined &&
      this.internalModel.description !== '' && this.internalModel.description !== undefined
    this.$emit('valid-for-publishing', isValid)
    return isValid
  }

  get fields (): Form[] {
    return [
      {
        id: 'jobType',
        key: 'jobType',
        type: InputTypes.SELECT,
        required: true,
        label: this.$i18n.t('job-ad.description.type').toString(),
        options: this.jobTypeOptions
      },
      {
        id: 'workingHours',
        key: 'workingHours',
        type: InputTypes.RADIO,
        label: this.$i18n.t('job-ad.description.working-hours').toString(),
        options: this.workingHours
      },
      {
        id: 'location',
        key: 'location',
        type: InputTypes.TEXT,
        required: true,
        label: this.$i18n.t('job-ad.description.city').toString()
      },
      {
        id: 'dummy',
        key: 'dummy',
        type: InputTypes.DUMMY
      },
      {
        id: 'startDate',
        key: 'startDate',
        type: InputTypes.DATE,
        required: true,
        label: this.$i18n.t('job-ad.description.start-date').toString(),
        disabledDates: {
          start: undefined,
          end: this.internalModel.endDate ? this.internalModel.endDate : undefined
        }
      },
      {
        id: 'endDate',
        key: 'endDate',
        type: InputTypes.DATE,
        label: this.$i18n.t('job-ad.description.end-date').toString(),
        disabledDates: {
          start: this.internalModel.startDate !== null ? this.internalModel.startDate : undefined,
          end: undefined
        }
      }
    ]
  }

  get yesterday (): Date {
    const date = new Date()
    date.setDate(date.getDate() - 1)
    return date
  }

  get jobDescriptionFields (): Form[] {
    return [
      {
        id: 'description',
        key: 'description',
        type: InputTypes.TEXTAREA,
        required: true,
        label: this.$i18n.t('job-ad.description.abstract').toString(),
        md: 12
      }
    ]
  }

  get publishFields (): Form[] {
    return [
      {
        id: 'publish',
        key: 'publish',
        type: InputTypes.CHECKBOX,
        label: this.$i18n.t('job-ad.description.is-job-ad-public').toString(),
        disabled: !this.isValidForPublishing
      },
      {
        id: 'endPublication',
        key: 'endPublication',
        type: InputTypes.DATE,
        label: this.$i18n.t('job-ad.description.online-until').toString(),
        disabledDates: {
          start: undefined,
          end: undefined
        },
        disabled: !this.isValidForPublishing
      }
    ]
  }

  mounted (): void {
    let root = document.getElementById('job-ad-description-form')
    if (root !== null) {
      Unescape.setSoftHyphensInLabels(root)
    }

    root = document.getElementById('job-description-form')
    if (root !== null) {
      Unescape.setSoftHyphensInLabels(root)
    }

    this.internalModel = {
      ...this.initialModel,
      jobType: this.initialModel.jobType ? this.initialModel.jobType : '',
      location: this.initialModel.location ? this.initialModel.location : '',
      workingHours: this.initialModel.workingHours ? this.initialModel.workingHours : 'FULL_TIME',
      startDate: this.initialModel.startDate ? this.initialModel.startDate : undefined,
      endDate: this.initialModel.endDate ? this.initialModel.endDate : undefined,
      endPublication: this.initialModel.endPublication ? this.initialModel.endPublication : undefined,
      description: this.initialModel.description ? this.initialModel.description : '',
      title: this.initialModel.title ? this.initialModel.title : ''
    }

    this.getJobTypes().then(data => {
      this.jobTypes = data
    })
  }
}
