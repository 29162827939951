







































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import InfoBox from '@/components/hszg/InfoBox.vue'
import { KeyValue } from '@/store/commonFormInput/types'
import Enums from '@/common/Enums'
import { Language } from '@/store/student/types'
import bootstrapBreakPoints from '@/common/breakpointsBootstrap'
import { State } from 'vuex-class'
import SelectWithLevel from '@/components/forms/SelectWithLevel.vue'

@Component({
  components: { SelectWithLevel, InfoBox }
})
/*
The form component to add/edit language skills for a profile or job ad.
It contains the SelectWithLevel component (a select input for the languages and a select input for the levels).
@group FORMS
 */
export default class LanguagesEditorForm extends Vue {
  @State('currentScreenWidth') screenWidth: number

  // unique identifier
  @Prop()
  id: string

  // if its writable (mostly used in combination with role rights)
  @Prop()
  writeable: boolean

  // if its readonly (mostly used in combination with role rights)
  @Prop()
  readonly: boolean

  // the languages available in the select input
  @Prop()
  languages: Language[]

  // disable the (skill)level input
  @Prop({ default: false })
  noLevelInput: boolean

  // an array that depicts the invalid states of the inputs (used to add the red invalid warnings)
  @Prop({ default: () => [] })
  invalidStates: boolean[]

  @Watch('languages', { immediate: true })
  onLanguagesChange (newValue: Language[]): void {
    this.talentLanguages = [...newValue]
    this.inputLanguages(false)
  }

  talentLanguages: Language[]

  get md (): boolean {
    return this.screenWidth > bootstrapBreakPoints.md
  }

  get languageOptions (): KeyValue[] {
    return Enums.LANGUAGES.map(key => {
      return {
        value: key,
        text: this.$i18n.t('LANGUAGES.' + key).toString()
      }
    })
  }

  get languageLevels (): KeyValue[] {
    return Enums.LANGUAGE_LEVELS.map(key => {
      return {
        value: key,
        text: this.$i18n.t('LANGUAGE_LEVELS.' + key).toString()
      }
    })
  }

  removeLanguage (i: number): void {
    this.talentLanguages = this.talentLanguages.filter((t, j) => j !== i)
    this.updateLanguageOptions()
    this.addLanguageField()
    this.emitLanguages()
  }

  addLanguageField (): void {
    // add new input field (if any option is remaining)
    if (!this.talentLanguages.length || (this.talentLanguages[this.talentLanguages.length - 1].value !== '' && this.talentLanguages.length < this.languageOptions.length)) {
      this.talentLanguages.push({ value: '', level: '' })
    }
  }

  updateLanguageOptions (): void {
    // disable the already used input fields
    // usage of $set to keep reactivity
    this.languageOptions.map(l => this.$set(l, 'disabled', this.talentLanguages.map(ul => Object.values(ul).includes(l.value)).includes(true)))
  }

  inputLanguages (emitLanguages: boolean): void {
    this.addLanguageField()
    this.updateLanguageOptions()

    if (emitLanguages) {
      this.emitLanguages()
    }
  }

  emitLanguages (): void {
    const filtered = this.talentLanguages.filter(lang => lang.value !== '')
    this.$emit('languagesChanged', filtered)
  }
}
