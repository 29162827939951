

















































import Vue from 'vue'
import Component from 'vue-class-component'
import BaseForm from '@/components/forms/BaseForm.vue'
import { Prop, Watch } from 'vue-property-decorator'
import { JobAdType } from '@/store/jobAd/types'
import { Form } from '@/store/commonFormInput/types'
import formTypes from '@/common/formTypes'

@Component({
  components: { BaseForm }
})
// Component for the second step of the job ad editor (company describes activities of the job)
// @group JOB AD EDITOR
export default class ActivityDescription extends Vue {
  // is the form readonly (input user right)
  @Prop({ default: false })
  readonly: boolean

  // is the form writeable (input user right)
  @Prop({ default: false })
  writeable: boolean

  @Prop()
  internalModel: JobAdType

  @Watch('internalModel', { immediate: true })
  onModelChanged (): void {
    this.updateModel()
  }

  private activityModel: any = {}
  private activitiesCount = 0

  get activityFields (): Array<Form> {
    const fields: Form[] = []
    const modelEntries = Object.entries(this.activityModel)
    for (let i = 0; i < this.activitiesCount; i++) {
      fields.push({
        id: modelEntries[i][0],
        key: modelEntries[i][0],
        type: formTypes.TEXT,
        label: (i + 1).toString(),
        disabled: false,
        edit: true,
        deletable: i > 4,
        required: false,
        md: 12
      })
    }
    return fields
  }

  /**
   * Add item to activityModel and
   * update activitiesCount in order to display the correct amount of input fields
   */
  public addActivity (): void {
    const countOfInputFields = Object.entries(this.activityModel).length
    if (countOfInputFields < 7) {
      this.activityModel['activity-' + countOfInputFields] = ''
      this.activitiesCount++
    }
  }

  /**
   * Remove one item from activityModel
   * Therefore reorder all other fields and dismiss the entry to be removed
   * Update activityModel and propagate changes to parent component
   **/
  public removeActivity (id: string): void {
    if (this.activitiesCount > 5) {
      let arrayIndex = 0
      const updatedActivities: any = {}
      Object.entries(this.activityModel).map(entry => {
        if (entry[0] !== id) {
          updatedActivities['activity-' + arrayIndex] = entry[1] as string
          arrayIndex++
        }
      })
      this.activityModel = updatedActivities
      this.activitiesCount--
      this.returnModelAsArray()
    }
  }

  /**
   * Propagate each change of value to the parent component via Input-event
   */
  public returnModelAsArray (): void {
    const returnArray: string[] = []
    Object.entries(this.activityModel).map((entry) => {
      const entryAsString = entry[1] as string
      if (entryAsString !== '') {
        returnArray.push(entryAsString)
      }
    })
    this.$emit('input', returnArray)
  }

  /**
   * React to model changes that are coming from parent component
   * Make sure that activityModel always contains at least 5 properties in order to render at least 5 input fields
   */
  public updateModel (): void {
    this.activityModel = {}
    this.activitiesCount = 0
    const initialLengthOfActivities = this.internalModel.activities ? this.internalModel.activities.length : 0
    const placeholdersToBeAdded = 5 - initialLengthOfActivities
    if (this.internalModel.activities !== undefined) {
      this.internalModel.activities.forEach((activity, index) => {
        if (activity === undefined) {
          this.activityModel['activity-' + index] = ''
        } else {
          this.activityModel['activity-' + index] = activity
        }
        this.activitiesCount++
      })
    }

    for (let i = 0; i < placeholdersToBeAdded; i++) {
      this.activityModel['activity-' + (initialLengthOfActivities + i).toString()] = ''
      this.activitiesCount++
    }
  }

  mounted (): void {
    this.updateModel()
  }
}
